import classnames from "classnames";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { inject } from "mobx-react";
import type { Store } from "@inferno/renderer-shared-core";

import { DATETIME_DISPLAY_FORMATS } from "@inferno/renderer-shared-core";

export interface DateTimeDisplayProps {
  format?: string;
  timestamp: number;
  className?: string;
  zone_id?: string;
  store?: Store;
}

export const DateTimeDisplay = inject("store")((props: DateTimeDisplayProps) => {
  const timeClass = classnames("component-datetime-display", {
    [`${props.className}`]: props.className,
  });

  const { store } = props;
  if (!store) {
    return null;
  }
  const { env } = store;
  const { zone_id = env.DEFAULT_TIMEZONE } = props;
  const ts = zonedTimeToUtc(props.timestamp.toString().length < 13 ? props.timestamp * 1000 : props.timestamp, zone_id);
  const utcTs = utcToZonedTime(ts, zone_id);
  const timestamp = utcTs.getFullYear() < 2000 ? ts : utcTs;

  const displayFormat = props.format || DATETIME_DISPLAY_FORMATS.DEFAULT;
  const formattedTimestamp = format(timestamp, DATETIME_DISPLAY_FORMATS.INFO);
  const displayTimestamp = format(timestamp, displayFormat);

  return (
    <time className={timeClass} dateTime={formattedTimestamp} data-timezone={props.zone_id}>
      {displayTimestamp}
    </time>
  );
});

export default DateTimeDisplay;
